import { useCallback, useState, FunctionComponent, useEffect } from 'react';
import { checkEmailValidity } from 'ts-frontend/helpers';
import parseJwt from '@/utils/jwt';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import OTPInputView from '../components/OTPInputView';
import EmailVerified from '../components/EmailVerified';
import useEmailVerification from '../hooks/useEmailVerification';
import useVerifiedEmailRedirect from '../hooks/useVerifiedEmailRedirect';
import getParamByName from '../../utils/queryString';

const EmailSentOTP: FunctionComponent<
  RouteComponentProps & {
    email: string;
    roomID: number;
  }
> = ({ email, roomID }) => {
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [showSucessScreen, setShowSucessScreen] = useState<boolean>(false);
  const firstNameParam = decodeURIComponent(getParamByName('firstName') || '');
  const firstName = firstNameParam && firstNameParam !== 'null' ? firstNameParam : undefined;
  const userEmail = email || getParamByName('email') || '';
  const [userEmailDisplay, setUserEmailDisplay] = useState(userEmail);
  const token = getParamByName('token');
  const otpTokenParam = getParamByName('otpToken');
  const [state, { postOTP, resetErrorAction, postResendOTP }] = useEmailVerification();
  const { emailSent, isLoading, error, emailVerifiedRedirect } = state;

  const otpToken = state.otpToken || otpTokenParam;

  const { userID: userIDString }: { userID: string | null } = token
    ? parseJwt(token)
    : { userID: null };
  const userID = userIDString ? Number(userIDString) : null;

  const isError = !!error || !otpToken;
  const isSuccess = !!emailVerifiedRedirect;

  useVerifiedEmailRedirect(state, { disabled: !showSucessScreen, usedOTP: true });

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        setShowSucessScreen(true);
      }, 1000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (!checkEmailValidity(userEmail)) {
      setUserEmailDisplay('Invalid Email');
    }
  }, [userEmail]);

  const handleOnPress = useCallback(() => {
    if (otpToken && verificationCode && userEmail) {
      setVerificationCode('');
      postOTP(otpToken, verificationCode, userEmail);
    }
  }, [verificationCode, otpToken, userEmail, postOTP]);

  useEffect(() => {
    if (verificationCode && verificationCode.length === 6) {
      handleOnPress();
    }
  }, [handleOnPress, verificationCode]);

  const clearVerificationCodeErrorAction = useCallback(() => {
    resetErrorAction();
  }, [resetErrorAction]);

  const handleOnResendToken = useCallback(() => {
    if (otpToken && userID && userEmail) {
      postResendOTP(otpToken, userID, userEmail);
    }
  }, [postResendOTP, otpToken, userID, userEmail]);

  if (showSucessScreen) {
    return <EmailVerified firstName={firstName} />;
  }

  return (
    <OTPInputView
      userEmail={userEmailDisplay}
      token={token}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      emailSent={emailSent}
      verificationCode={verificationCode}
      setVerificationCode={setVerificationCode}
      onPress={handleOnPress}
      onClearError={clearVerificationCodeErrorAction}
      onResendToken={handleOnResendToken}
    />
  );
};

export default withRouter(EmailSentOTP);
