import apiWrapper from '../../core/api/apiWrapper';
import { ApiResponse } from '../../myAccount/utils/myAccountApiHelper';
import apiHelper from '../../core/api/apiHelper';

export interface ChangeEmailPayload {
  userID: number;
  email: string;
  token: string;
}

export interface ChangeEmailResponse {
  data: {
    email: string;
    pendingEmail: string;
    emailVerificationStatus: string;
    token?: string;
  };
}

export interface PostOTPPayload {
  email: string;
  otpKey: string;
  otpToken: string;
}

export interface PostResendOTPPayload {
  email: string;
  userID: number;
  otpToken: string;
}

export interface PostOTPResponse {
  data: { redirectUrl: string };
}

export interface PostResendOTPResponse {
  data: { otpToken: string };
}

function checkEmailVerificationToken(userID: number, token: string): Promise<ApiResponse> {
  return apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/email-verification`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

async function changeEmail(payload: ChangeEmailPayload): Promise<ApiResponse<ChangeEmailResponse>> {
  const { userID, email, token } = payload;
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/basic-details`,
    { email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      shouldIgnore401: true,
    }
  );
}

async function postOTP(payload: PostOTPPayload): Promise<ApiResponse<PostOTPResponse>> {
  const { otpKey, otpToken, email } = payload;
  return apiWrapper.post(
    `${apiHelper().authAPIEndpoint}/v2/auth/email-verification/otp`,
    { email, otpKey, otpToken },
    {
      shouldIgnore401: true,
    }
  );
}

async function postResendOTP(
  payload: PostResendOTPPayload
): Promise<ApiResponse<PostResendOTPResponse>> {
  const { userID, otpToken, email } = payload;
  return apiWrapper.post(
    `${apiHelper().authAPIEndpoint}/v2/auth/email-verification/otp/resend`,
    { userID, email, otpToken },
    {
      shouldIgnore401: true,
    }
  );
}

const emailVerificationApiHelper = {
  checkEmailVerificationToken,
  changeEmail,
  postOTP,
  postResendOTP,
};

export default emailVerificationApiHelper;
