export interface State {
  emailSent: boolean;
  expiredTokenEmail: string;
  emailVerifiedRedirect: string;
  changeEmailToken: string;
  error: string;
  isLoading: boolean;
  skipDeeplink: boolean;
  userID?: string | number | null;
  otpToken: string | null;
}

export const initialState: State = {
  emailSent: false,
  expiredTokenEmail: '',
  emailVerifiedRedirect: '',
  changeEmailToken: '',
  error: '',
  isLoading: false,
  skipDeeplink: false,
  userID: null,
  otpToken: null,
};

export type Action =
  | { type: 'requestSendEmailVerification' }
  | {
      type: 'receiveSendEmailVerification';
      payload: Pick<State, 'emailSent'>;
    }
  | { type: 'requestChangeEmail' }
  | { type: 'receiveChangeEmail'; payload: Pick<State, 'changeEmailToken'> }
  | { type: 'setError'; error?: string }
  | { type: 'resetError' }
  | { type: 'requestCheckEmailVerificationToken' }
  | {
      type: 'receiveCheckEmailVerificationToken';
      payload: Pick<State, 'emailVerifiedRedirect' | 'skipDeeplink' | 'userID'>;
    }
  | {
      type: 'setExpiredTokenEmail';
      payload: Pick<State, 'expiredTokenEmail'>;
    }
  | { type: 'requestSendCognitoUserConfirmation' }
  | { type: 'receiveSendCognitoUserConfirmation' }
  | { type: 'requestPostOTP' }
  | { type: 'receivePostOTP'; payload: Pick<State, 'emailVerifiedRedirect'> }
  | { type: 'requestResendPostOTP' }
  | { type: 'receiveResendPostOTP'; payload: Pick<State, 'otpToken'> };

export const emailVerificationReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'requestSendEmailVerification':
    case 'requestCheckEmailVerificationToken':
    case 'requestChangeEmail':
    case 'requestPostOTP':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveCheckEmailVerificationToken':
    case 'setExpiredTokenEmail':
    case 'receiveSendEmailVerification':
    case 'receiveChangeEmail':
    case 'receivePostOTP':
    case 'receiveResendPostOTP':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: '',
      };
    case 'requestSendCognitoUserConfirmation': {
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    }
    case 'receiveSendCognitoUserConfirmation': {
      return {
        ...state,
        isLoading: false,
        emailSent: true,
        error: '',
      };
    }
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    case 'resetError':
      return {
        ...state,
        error: '',
        isLoading: false,
      };
    default:
      return state;
  }
};
